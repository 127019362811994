import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          alignCenter
          justifyBetween
        >
          <Header />
          <CFView mb="25px" w="100%" column center textCenter>
            <CFView w="85%">
              <CFText white xBold style={{ fontSize: 35, lineHeight: '45px' }}>
                WELCOME TO HAKONE SUSHI
              </CFText>
            </CFView>
            <CFView mt="15px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="690px"
          w="100%"
          image={`url(${hero}) bottom / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
        >
          <Header />
          <CFView row center bg="rgba(198,0,0,.75)">
            <CFText white xBold style={{ fontSize: 29 }} raleway>
              WELCOME TO HAKONE SUSHI
            </CFText>
            <CFView pulsate ml="25px" mt="4px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
