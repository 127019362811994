import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promo1, promo2, promo3, promotions } from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-1px">
          <CFImage src={mobilePromotion} w="100%" alt="Mobile Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="auto" maxWidth="2000px">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
