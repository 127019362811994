import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          <CFImage style="display: block" src={mobileAbout} w="100%" alt="About"/>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="auto" maxWidth="2000px">
          <CFImage src={about} mb="-15px" w="100%" alt="About"/>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
